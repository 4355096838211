@import '~pjm-dls/dist/styles/base/variables';
//Profile App Style
.pjm-profile-banner {
  padding: 30px;
  padding-bottom: 150px;
  margin: -30px;
  margin-bottom: -130px;
  background-color: $sidebar-bg;
  color: $white;
  position: relative;
  z-index: 0;

  & .breadcrumb {
    background-color: initial;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 15px;
    padding-bottom: 65px;
    margin: -10px;
    margin-bottom: -75px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $white;
  }

  & .pjm-link {
    color: $white;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $secondary;
    }
  }
}

.pjm-profile-banner-top {
  @include display-flex(flex, row, wrap);
  @include align-items(center);
  margin-bottom: 40px;

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);
    margin-bottom: 25px;
  }
}

.pjm-profile-banner-top-left {
  @include display-flex(flex, row, wrap);
  @include align-items(center);

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);
    margin-bottom: 25px;
  }
}

.pjm-profile-banner-avatar {
  margin-right: 1.5rem;

  @media (max-width: 575px) {
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.pjm-profile-banner-top-right {
  text-align: center;
  margin-left: auto;

  @media (max-width: 575px) {
    margin-left: 0;
  }
}

.pjm-profile-banner-bottom {
  @include display-flex(flex, row, wrap);
  @include align-items(center);

  @media (max-width: 575px) {
    @include display-flex(flex, column, nowrap);

    .pjm-tab-list {
      margin-bottom: 10px;
    }
  }
}

.pjm-profile-content {
  position: relative;
  z-index: 2;
}

.pjm-profile-setting {
  margin-left: auto;

  @media (max-width: 575px) {
    margin-left: 0;
  }
}

.pjm-profile-content-right {
  @media (max-width: 575px) {
    @include display-flex(flex, row, wrap);
    @include align-items(center);

    .text-truncate {
      width: auto;
      margin-left: auto;
    }
  }
}

.pjm-pro-contact-list {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.pjm-card-profile {
  position: relative;

  &-sm {
    position: relative;
  }

  & .card-header {
    background-color: transparent;
    border-bottom: 0 none;
    padding: 18px $pjm-card-padding;

    @media (max-width: 575px) {
      border-bottom: solid 1px $border-color;
    }
  }

  & .card-title {
    font-size: $h2-font-size;
    text-transform: capitalize;
  }

  & .pjm-tabs-up {
    margin-top: -50px;

    @media (max-width: 575px) {
      margin-top: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  & .pjm-tabs-content {
    padding: 10px $pjm-card-padding 7px;
  }
}
