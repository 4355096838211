@import '~pjm-dls/dist/styles/base/variables';
@import '~pjm-dls/dist/styles/base/mixins';

.no-wrap {
  white-space: nowrap;
}
.title-heading {
  line-height: 1.5rem;
}
.tabs-container {
  .MuiTab-root {
    color: #565656;
    min-width: 50px !important;
    opacity: 1;
    padding-left: 0px;
    margin-left: 12px;
    margin-right: 12px;
    padding-right: 0px;

    .MuiTab-wrapper {
      color: #565656;
      font-weight: 500;
      display: flex;
      flex-direction: revert;
      align-items: center;
      justify-content: center !important;
      text-align: center !important;
      font-size: $font-size-14;
      line-height: 25.88px;
    }

    &.Mui-selected {
      .MuiTab-wrapper {
        color: #003f2d !important;
      }
    }

    &:before,
    &:after {
      content: unset;
    }
  }
}
.under-maintenance-estimator {
  & .heading {
    font-size: 24px;
    color: red;
    text-align: center;
    font-family: 'Calibre', sans-serif !important;
  }
  & .message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 50%;
    margin: 0 25%;
  }
  & .banner {
    img {
      width: 25%;
      margin: 0 37%;
      margin-top: 50px;
    }
  }
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    & .message {
      width: 75%;
      margin: 0 15%;
      margin-top: 25px;
    }
    & .banner {
      img {
        width: 25%;
        margin: 0 37%;
        margin-top: 50px;
      }
    }
  }
  @media screen and (max-width: 991px) and (min-width: 776px) {
    & .message {
      width: 70%;
      margin: 0 18%;
      margin-top: 10px;
    }
    & .banner {
      img {
        width: 30%;
        margin: 0 35%;
        margin-top: 50px;
      }
    }
  }
  @media screen and (max-width: 775px) {
    & .message {
      width: 85%;
      margin: 0 10%;
      margin-top: 35px;
    }
    & .banner {
      img {
        width: 30%;
        margin: 0 35%;
        margin-top: 50px;
      }
    }
  }
}
.collapse-menu-cdf {
  font-size: 16px;
  line-height: 18px;
  padding-top: 1px;
}

.preview-field-heading {
  font-size: $font-size-sm;
  line-height: 16px;
  @include align-items(center);
  letter-spacing: 0.4px;
  color: $black;
}

.customDropdownArrowGreen {
  content: '';
  z-index: 9;
  background-repeat: no-repeat;
  background-position: 100%;
  width: 8px;
  height: 8px;
  right: calc(100% - 272px);
  top: 14px;
  border: 2px solid $color-primary;
  border-top: 0;
  border-left: 0;
  @include transform(rotate(45deg));
  @include transition(all 0.3s);
}

.header-user-nav {
  padding-left: 10px !important;
}

.side-nav-menu {
  & .MuiList-padding {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}

.top-71 {
  top: 71px !important;
}

.color-red {
  color: $color-red;
}

.app-sidebar-content .MuiBackdrop-root {
  top: 71px;
}

// maintenance page styles
.maintenance-wrapper {
  padding: 100px 20px 0;

  a {
    text-decoration: none;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 250px;
    margin: 0 0 10px;
  }

  h2 {
    font-size: 20px;
    line-height: 30px;
    color: #212529 !important;
  }

  .body-2 {
    font-size: $font-size-16;
    line-height: 18px;
  }
}

.status-icon {
  position: absolute;
  top: 20px;
  left: 25px;

  img {
    width: 100%;
    vertical-align: baseline;
    max-width: 18px;
  }
}
.icon-right-pos {
  float: right;
  display: flex;
  margin-top: -20px;
  margin-bottom: 25px;
}
.remind-icon {
  z-index: 1;
  .icon-text {
    margin-left: 15px;
    font-family: 'Calibre', sans-serif !important;
    font-size: 0.95rem;
    color: rgb(0, 63, 45);
    .MuiSvgIcon-root {
      font-size: 1.1rem;
    }
    .text-pos {
      position: relative;
      top: -2px;
    }
  }

  img {
    vertical-align: baseline;
    max-width: 18px;
  }
}
.approver-button-pos {
  z-index: 1;
  .icon-text {
    margin-left: 15px;
    font-family: 'Calibre', sans-serif !important;
    font-size: 0.95rem;
    color: rgb(0, 63, 45);
    .MuiSvgIcon-root {
      font-size: 1.1rem;
    }
    .text-pos {
      position: relative;
      top: -2px;
    }
  }

  img {
    vertical-align: baseline;
    max-width: 18px;
  }
}

.kahua-status-icon {
  padding-left: 1.2rem;

  img {
    cursor: pointer;
    width: 100%;
    // vertical-align: baseline;
    min-width: 24px;
    max-width: 24px;
    margin-right: 2px;
  }

  img.kahua-icon {
    min-width: 16px;
    max-width: 16px;
    margin-left: 7px;
  }

  .no_status {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 2px;
  }
}

.kahua-status-cp-icon {
  img {
    cursor: pointer;
    width: 100%;
    min-width: 16px;
    max-width: 16px;
    margin-right: 2px;
  }

  img.kahua-icon {
    min-width: 16px;
    max-width: 16px;
    margin-left: 7px;
  }

  .no_status {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 2px;
  }
}

.missing-fields-container {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}

.missing-fields-image {
  width: 20px;
  height: 20px;
}

.tooptip-missing-fields {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 16px;
  color: #000;
  text-align: left;
}

.header-user-nav-dropdown {
  top: 15px !important;
}

.header-filter-nav-dropdown {
  top: 100% !important;
  right: 0 !important;
  transform: none !important;
  left: auto !important;
}

.react-responsive-modal-root {
  z-index: 9999 !important;

  .react-responsive-modal-overlay {
    background: rgba(25, 24, 24, 0.2) !important;
  }

  .react-responsive-modal-container {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.2rem;

    .react-responsive-modal-modal {
      max-width: 800px;
      position: relative;
      padding: 1.2rem;
      background: #ffffff;
      background-clip: padding-box;
      box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
      margin: auto;
    }
  }
}

.styles_overlay__CLSq-:not(.exportModalWindow):not(.customModalPopupBorder),
.react-responsive-modal-container:not(.exportModalWindow):not(.customModalPopupBorder) {
  background: rgba(25, 24, 24, 0.2);

  & .validationErrorMessage {
    &.fc-black {
      color: $color-text-dark;
    }
  }
  & div:first-child {
    border-top: solid;
    overflow-y: auto;
    border-top-color: $color-primary;
    border-top-width: 5px;
  }
}

.confirmationModalButton {
  margin: auto;
  display: flex;
  justify-content: center;
  & button:not(last-child) {
    margin-right: 15px;
  }
}

.validationErrorMessageButton {
  display: flex;
  @include align-items(center);
  @include justify-content(center);
}

.fc-red,
.slider-asterix {
  color: $color-error-main;
}

.validationErrorMessage {
  color: $color-error-main;
  padding-top: 15px;
}

.confirmationModalButton {
  display: flex;
  @include align-items(right);
  @include justify-content(center);
}

.rotating-downloading-icon {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.font-family-serif {
  font-family: serif;
}

.green .side-nav .user-profile {
  margin-right: 0;
  margin-left: 0;
}

.side-nav .user-avatar {
  width: 100px !important;
  border-radius: 0;

  & img {
    object-fit: fill;
  }
}

.mini-drawer .side-nav .user-avatar {
  width: 44px !important;
  margin: 0 !important;
  border-radius: 0;

  & img {
    object-fit: fill;
  }
}

.mini-drawer .side-nav:hover .user-avatar {
  width: 100px !important;
  @include transition(all 100ms);
  border-radius: 0;

  & img {
    object-fit: fill;
  }
}

.landingPageBGImage {
  padding-left: 0;
  padding-right: 0;

  .dashboard-header {
    height: 34vh;
    position: relative;

    .dashboard-header-content {
      /*background: url("../assets/images/logo/landing_page_background.png") no-repeat;*/
      background: url('../assets/images/logo/marqueeimage.jpg') no-repeat;
      background-size: cover;
      min-height: 100%;
      opacity: 0.5;
      @include transition(opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms);
    }
  }
}

.pjmProductsMain {
  font-size: 4vh;
  text-align: center;
  font-weight: 500;
}

.accessErrorMessage {
  padding-top: 250px;
  text-align: center;
}

.pjmWelcomeHeader {
  // height: 40vh;
  font-family: $font-family-financier-display;
  font-weight: 400;
  padding-top: 1vh;
  position: absolute;
  color: $color-primary;
  width: 100%;
  height: 100%;
  z-index: 8;
}

.pjmWelcome {
  font-weight: 500;
  font-size: 5vh;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 24px $white;
  margin: 1vh 0;
}

.pjmSubHeading {
  color: $color-primary;
  font-family: $font-family-calibre;
  font-size: 2vh;
  text-align: center;
  width: 100%;
  position: absolute;
  z-index: 23;
  margin-top: 1vh;
}

.pjmArrowIcon {
  text-align: center;
  color: $color-primary;
  padding-top: 4vh;
  width: 100%;
  position: absolute;
}

.card-empty {
  margin-bottom: 0;
  display: none;
}

.pjmIcons {
  max-width: 890px;
  margin: auto;
  position: relative;
  top: -85px;
  @include border-radius(4px);
  z-index: 100;

  .pjmIconsContent {
    /*@include box-shadow(
      5px 0 17px -8px rgba(127, 127, 127, 0.34902),
      -5px 0 17px -10px rgba(127, 127, 127, 0.34902)
    );*/

    .pjmIconContainer {
      @include transition(all 0.2s ease-in-out);
      border: 1px solid $gray-300;
      margin-bottom: 0;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 172px;

      & a {
        text-decoration: none;

        & .pjmProductTitle {
          font-size: $font-size-14;
          line-height: 18px;
          margin-top: 10px;
          @include font-smoothing();
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    .pjmIconContainer:hover {
      @include transform(scale(1.03));
      @include transition(0s ease-in-out);
      z-index: 1;
      @include border-radius(4px !important);
      text-decoration: none;
      @include box-shadow(5px 0 10px -4px rgba(127, 127, 127, 0.34902), -5px 0 10px -4px rgba(127, 127, 127, 0.34902));

      .pjmProducts {
        border: none;
      }
    }

    .analyticsProduct.disabled {
      & .backgroundImageDashboardIcons {
        @include filter(grayscale(100%));
      }

      & .pjmProducts {
        color: silver;
        cursor: not-allowed;

        & .pjmProductTitle {
          font-size: 15px;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }

    .disabledCard {
      position: relative;
      flex: 1 0 auto;

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000000;
        opacity: 0.4;
        text-align: center;

        .comingSoon {
          margin-top: 35%;
          color: white;
          font-size: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: 575px) {
    .capitalPlanProduct:hover ~ .pjmProductsCpHelpText,
    .estimatorProduct:hover ~ .pjmProductsEstHelpText,
    .projectTrackerProduct:hover ~ .pjmProductsPtHelpText,
    .officeReadyProduct:hover ~ .pjmProductsOfrHelpText,
    .kahuaProduct:hover ~ .pjmProductsKahHelpText,
    .spacerProduct:hover ~ .pjmProductsSpacerHelpText,
    .plansProProduct:hover ~ .pjmProductsPlansProHelpText,
    .principalLiteProduct:hover ~ .pjmProductsPrincipalLitenHelpText,
    .analyticsProduct:hover ~ .pjmProductsAnalyticsHelpText,
    .programManagementProduct:hover ~ .pjmProductsManagementHelpText,
    .okay2PayProduct:hover ~ .okay2PayHelpText,
    .justificationAndAuthorization:hover ~ .pjmProductsJustificationHelpText {
      display: block !important;
    }
  }

  @media screen and (max-width: 575px) {
    max-width: 55%;

    .capitalPlanProduct:hover ~ .pjmProductsCpHelpText,
    .estimatorProduct:hover ~ .pjmProductsEstHelpText,
    .projectTrackerProduct:hover ~ .pjmProductsPtHelpText,
    .officeReadyProduct:hover ~ .pjmProductsOfrHelpText,
    .kahuaProduct:hover ~ .pjmProductsKahHelpText,
    .spacerProduct:hover ~ .pjmProductsSpacerHelpText,
    .plansProProduct:hover ~ .pjmProductsPlansProHelpText,
    .analyticsProduct:hover ~ .pjmProductsAnalyticsHelpText,
    .programManagementProduct:hover ~ .pjmProductsManagementHelpText,
    .okay2PayProduct:hover
      ~ .okay2PayHelpText
      .justificationAndAuthorization:hover
      ~ .pjmProductsJustificationHelpText {
      display: none !important;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @media screen and (max-width: 1199px) {
      .capitalPlanProduct:hover ~ .pjmProductsCpHelpText,
      .estimatorProduct:hover ~ .pjmProductsEstHelpText,
      .projectTrackerProduct:hover ~ .pjmProductsPtHelpText,
      .officeReadyProduct:hover ~ .pjmProductsOfrHelpText,
      .kahuaProduct:hover ~ .pjmProductsKahHelpText,
      .spacerProduct:hover ~ .pjmProductsSpacerHelpText,
      .plansProProduct:hover ~ .pjmProductsPlansProHelpText,
      .analyticsProduct:hover ~ .pjmProductsAnalyticsHelpText,
      .programManagementProduct:hover ~ .pjmProductsManagementHelpText,
      .okay2PayProduct:hover ~ .okay2PayHelpText,
      .justificationAndAuthorization:hover ~ .pjmProductsJustificationHelpText {
        padding-top: 5px !important;
      }
    }
  }
}

.backgroundImageSideMenuEstimator {
  background-image: url('../assets/images/icons/estimator.svg');
}

.backgroundImageCapitalPlan {
  background-image: url('../assets/images/icons/CapitalPlanner.svg');
}

.backgroundImageUserMgmt {
  background-image: url('../assets/images/icons/UserManagement.svg');
}

.backgroundImageCustomFieldMgmt {
  background-image: url('../assets/images/logo/customfield_icon.svg');
}

.backgroundImageApprovalConfiguration {
  background-image: url('../assets/images/logo/approval_configuration_icon.svg');
}

.backgroundImageDataAdmins {
  background-image: url('../assets/images/logo/cloud_hosting_icon.svg');
}

.backgroundImageOkay2Pay {
  background-image: url('../assets/images/icons/okay2pay.svg');
}

.backgroundImageDashboardIcons {
  /* background-image: url(../assets/images/logo/capitalplanning_cubes_white_small.png);*/
  background: url(../assets/images/logo/pjm-tools-icons-sprite-5.png) no-repeat;
  width: 55px;
  height: 55px;
  display: inline-block;
}

.backgroundImageDashboardProIcons {
  /* background-image: url(../assets/images/logo/capitalplanning_cubes_white_small.png);*/
  background: url(../assets/images/logo/pjm-tools-icons-sprite-5.png) no-repeat;
  width: 95px;
  height: 95px;
  display: inline-block;
}

.backgroundImageMyProject {
  background: url(../assets/images/logo/my_projects_icon.svg) no-repeat;
  background-size: 100%;
  width: 55px;
  height: 64px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImagePDA {
  background: url(../assets/images/logo/pda_icon.png) no-repeat;
  background-size: 100%;
  width: 64px;
  height: 57px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImagePDA-sm {
  background: url(../assets/images/logo/pda_icon.png) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImageAccess-sm {
  background: url(../assets/images/logo/access_icon.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImageApproval-sm {
  background: url(../assets/images/logo/approval_icon.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImageCustomField-sm {
  background: url(../assets/images/logo/custom_field_management_icon_new.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImageManageLookup-sm {
  background: url(../assets/images/logo/manage_lookup_icon.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImageMangeColum-sm {
  background: url(../assets/images/logo/manage_column_icon_new.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}

.backgroundImageDataAdmin-sm {
  background: url(../assets/images/logo/data_admin_icon.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}
.backgroundImageAdminPM-sm {
  background: url(../assets/images/logo/pm-logo.svg) no-repeat;
  background-size: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  min-width: 30px;
}
.backgroundImagePM {
  background-image: url('../assets/images/logo/program-management-icon.svg');
  background-size: 100%;
  display: inline-block;
  width: 47px;
  min-width: 46px;
  height: 55px;
}

.backgroundImagePM-sm {
  background-image: url('../assets/images/logo/program-management-icon.svg');
  background-size: 100%;
  min-width: 30px;
  display: inline-block;
}

.backgroundImageOkay2Pay-sm {
  background-image: url('../assets/images/logo/okay2pay.svg');
  background-size: 82%;
  min-width: 30px;
  display: inline-block;
}

.backgroundImageAdminIcon-sm {
  background-image: url('../assets/images/logo/admin_icon.svg');
  background-size: 100%;
  min-width: 30px;
  display: inline-block;
}

.proItem {
  background-position: -518px -11px;
  background-size: 28.8em;
  position: absolute;
  left: 0;
  top: 0;
}

.pjmProductsLogo {
  margin-top: 20px;
  width: auto;
  height: 46px;
  background-position: 222px 0;
}

.pjmProductsKahua {
  background-position: -305px 0px;
  background-size: 32em;
}

.pjmProductsAnalytics {
  background-position: -252px 0px;
  background-size: 32em;
}

.pjmProductsAuthorization {
  background-position: -565px -10px;
  background-size: 31em;
}

.pjmProductsCapitalPlan {
  background-position: 0 0;
  background-size: 35em;
}

.pjmProductsPM {
  background-position: 0 0;
  background-repeat: no-repeat;
}

.pjmProductsEstimator {
  background-position: -56px 0;
  background-size: 35em;
}

.pjmProductsProjectSplit {
  background-position: -103px 0;
  background-size: 33em;
}

.pjmProductsOfficeReady {
  background-position: -151px 0;
  background-size: 32em;
}

.pjmProductsSpacer {
  background-position: -393px 0;
  background-size: 31em;
}

.pjmProductsPlansPro {
  background-position: -356px 0;
  background-size: 32em;
}

.pjmProductsPrincipalLite {
  background-position: -897px -22px;
  background-size: 50em;
}

.backgroundImageDashboardIcons-sm {
  /* background-image: url(../assets/images/logo/capitalplanning_cubes_white_small.png);*/
  background: url(../assets/images/logo/pjm-tools-icons-sprite-5.png) no-repeat 0 0;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-size: 23.5em;
  min-width: 30px;
}

.nav-menu-item.disabled {
  & .nav-menu-link:hover {
    color: silver;
  }

  & .nav-menu-link {
    color: silver;
    cursor: not-allowed;
  }
}

.backgroundImageDashboardIcons-sm.disabled {
  @include filter(grayscale(100%));
}

.pjmProductsKahua-sm {
  background-position: -166px 0px;
  background-size: 25em;
}

.pjmProductsAnalytics-sm {
  background-position: -138px 0px;
  background-size: 25em;
}

.pjmAuthorization-sm {
  background-position: -301px -6px;
}

.pjmProductsCapitalPlan-sm {
  background-position: -2px 0;
  background-size: 29em;
}

.pjmProductsEstimator-sm {
  background-position: -26px 0;
  background-size: 25em;
}

.pjmProductsProjectSplit-sm {
  background-position: -57px 0;
  background-size: 26em;
}

.pjmProductsOfficeReady-sm {
  background-position: -86px 0;
  background-size: 26em;
}

.backgroundImagePL-sm {
  background: url(../assets/images/logo/my_projects_icon.svg) no-repeat;
  background-size: 100%;
  width: 28px;
  height: 34px;
}

.pjmProductsSpacer-sm {
  background-position: -222px 0px;
  background-size: 25em;
}

.pjmProductsPlansPro-sm {
  background-position: -194px 0px;
  background-size: 25em;
}

.tileHelpText {
  display: none !important;
  font-size: $font-size-16;
  margin-top: 20px;
}

.tileHelperText {
  display: none !important;
  font-size: $font-size-16;
  margin-top: 20px;
}

.sideMenuCustomIcons {
  width: 30px;
  height: 28px;
  background-repeat: no-repeat;
  color: $black;
  display: inline-block;
  margin-top: 4px;
}

.approval-config-icon-custom-width {
  height: 31px;
}

.sideMenuCustomIconsPJM {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  color: $black;
  display: inline-block;
  margin-top: 4px;
}

.sideMenuCollapseIcons {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  color: $black;
  display: inline-block;
  margin-top: 4px;
}

.sideMenuCollapseIconsPJM {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  color: $black;
  display: inline-block;
  margin-top: 4px;
}

.pjmProducts {
  text-align: center;
  font-size: $h2-font-size;
  font-weight: 400;
  color: $color-primary;
  font-style: normal;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ht-2 {
  height: 2px !important;
}

.capitalplan {
  .topsection {
    .textbold {
      font-size: 41px;
      color: #7f7f7f;
      text-align: left;
      font-weight: 600;
    }
  }

  &.topsection {
    h1 {
      color: $color-text-dark;
      font-weight: 600;
    }

    &.text {
      font-weight: 600;
      font-size: $h1-font-size;
      color: $color-text-dark;
      text-align: left;
    }
  }
}

.capital-plan-planstitle {
  .client_name {
    font-size: $font-size-16;
    margin-left: 4px;
  }
}

.admindataimport.topsection.text {
  font-weight: 400;
  font-size: $font-size-16;
  color: #7f7f7f;
  text-align: left;
  margin-left: 4px;
}

.admindataimport.topsection h1 {
  color: $color-text-dark;
}

.plan-list-banner {
  margin-bottom: -140px !important;
  padding-bottom: 150px;
  z-index: 0;
}

.pds-form-banner {
  padding-bottom: 30px;
  z-index: 0;
}

.plan-import-banner {
  margin-bottom: -120px !important;
  padding-bottom: 130px;
  z-index: 0;
}

.admin-data-import-banner {
  margin-bottom: -120px !important;
  padding-bottom: 130px;
  z-index: 0;
}

.capital-plan-list-header {
  /*z-index: 1300 !important;*/
  position: relative;
  padding-bottom: 20px;
}

.capital-plan-clientlogo {
  height: 100%;
  padding-left: 7px;
  padding-right: 1px;
  text-align: center;

  &.import-page-style {
    padding-left: 0;
  }
}

.capital-plan-planstitle {
  margin-left: 15px;
}

.capital-plan-list-top-btns {
  margin-top: 15px;
  margin-right: -15px;
  display: flex;
}

.capitalplan .topsection .button {
  display: flex;
  @include justify-content(space-between);
}

.admin-data-import-title {
  margin-left: 15px;
}

.admin-data-import-header {
  position: relative;
}

.project-tracker-list-header {
  position: relative;
  z-index: 2;

  & .project-tracker.topsection.text {
    font-weight: 400;
    font-size: $font-size-16;
    color: #7f7f7f;
    text-align: left;
    margin-left: 4px;
  }

  & .project-tracker-list-top-btns {
    margin-top: 15px;
    margin-right: -15px;
    display: flex;

    & .last {
      margin-right: 15px;
    }
  }

  .estimator-projects-list-top-btns {
    margin-top: 15px;
    margin-right: -15px;
    display: flex;
  }
}

.cap-plan-new-project-banner {
  z-index: 0;
}

.displayflex {
  display: flex;
}

.pie--btn-plus {
  color: $color-surface-white;
  content: url('data:image/svg+xml;utf8,<svg class="pie--btn__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><title>icon/content/add_circle_24px_sharp</title><path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M17,13 L13,13 L13,17 L11,17 L11,13 L7,13 L7,11 L11,11 L11,7 L13,7 L13,11 L17,11 L17,13 Z" id="path-1"/></svg>');
}

.main-loader-custom {
  margin-left: 5px;
  margin-right: 5px;
}

.main-backdrop-custom {
  z-index: 1301 !important;
  color: $color-surface-white;
  opacity: 0.5 !important;
}

// approvals css
.btn-client-approval {
  .MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    justify-content: center !important;
  }
}

@media screen and (max-width: 575px) {
  .landingPageBGImage {
    padding-top: 0;

    & .pjmProductsMain {
      font-size: 2.5vh;
    }

    & .pjmWelcome {
      font-size: 4vh;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .pjmIcons {
    max-width: 75%;
  }

  .card-empty {
    display: block;
    border: 1px solid $gray-400;
    border-left: 1px solid $gray-400 !important;
  }

  .card-group {
    & > .card:last-child {
      border-top-right-radius: 0;
    }

    & > .card:first-child {
      border-bottom-left-radius: 0;
    }

    & > .card:nth-child(3) {
      border-top-right-radius: 4px !important;
    }

    & > .card:nth-child(7) {
      border-bottom-left-radius: 4px !important;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .card-group {
    & .card:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
    }

    & > .card:first-child {
      border-bottom-left-radius: 0;
    }

    & > .card:nth-child(4) {
      border-top-right-radius: 4px !important;
    }

    & > .card:nth-child(5) {
      border-bottom-left-radius: 4px !important;
    }

    & > .card:nth-child(8) {
      border-bottom-right-radius: 4px !important;
    }
  }
}

@media screen and (min-width: 1200px) {
  .card-group {
    & .card:nth-child(8) {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }
}

.MuiTypography-body1,
.MuiPaper-root,
.MuiInputBase-root {
  color: $color-text-dark;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .MuiButton-label {
    margin-bottom: -1px;
  }

  .MuiButton-label {
    & span {
      margin-top: -4px;
    }
  }
}

.cursorAllowed {
  padding: 12px;
}

.searchtext-button .MuiInputBase-formControl {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  top: 1px;
  height: 36px;
}

.capitalplandashboardbutton .rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.cdfdashboardbutton .rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 53.63px !important;
}
.shadow-5 {
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.12),
    0 8px 16px rgba(0, 0, 0, 0.12),
    0 16px 32px rgba(0, 0, 0, 0.12);
}

.border-radius-md {
  border-radius: 5px;
}

.bg-white {
  background-color: #fff !important;
}

.cdfdashboardbutton .rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.cdf-header {
  /*z-index: 1300 !important;*/
  position: relative;
  padding-bottom: 20px;
}

.cdf {
  .topsection {
    .textbold {
      font-size: 41px;
      color: #7f7f7f;
      text-align: left;
      font-weight: 600;
    }
  }
  &.topsection {
    h1 {
      color: $color-text-dark;
      font-weight: 600;
    }
    &.text {
      font-weight: 600;
      font-size: $h1-font-size;
      color: $color-text-dark;
      text-align: left;
    }
  }
}

.tilesContainer {
  .col-desktop-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-desktop-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-desktop-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .col-desktop-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-desktop-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-desktop-6 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }

  /* For tablet */
  @media (max-width: 992px) {
    .col-tablet-1 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-tablet-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-tablet-3 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    .col-tablet-4 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-tablet-5 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .col-tablet-6 {
      flex: 0 0 16.66%;
      max-width: 16.66%;
    }
  }

  /* For mobile */
  @media (max-width: 576px) {
    .col-mobile-1 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-mobile-2 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-mobile-3 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    .col-mobile-4 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-mobile-5 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .col-mobile-6 {
      flex: 0 0 16.66%;
      max-width: 16.66%;
    }
  }
}

.add-personnel-button {
  & > .personnel-btn {
    display: none;
  }
  .personnel-btn {
    margin: 0 10px 10px;
  }
  @media (min-width: 600px) {
    .MuiSnackbar-anchorOriginBottomCenter {
      @include transform(translate(-50%, -60%));
      top: 40%;
    }
  }
}
