@import '~pjm-dls/dist/emerald/variables';
@import '~pjm-dls/dist/styles/base/variables';
@import '~pjm-dls/dist/styles/base/mixins';

.MuiSnackbarContent-message {
  width: 90% !important;
  text-align: center;
  font-size: 1rem;
}

.MuiSnackbarContent-action {
  width: 10%;
  margin: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 35px;
  justify-content: flex-end;
}
.alertSpacing {
  padding: 0px !important;
  color: $white;
  padding-left: 10px !important;
}

.alertSuccess {
  background-color: $color-primary;
}
.alertFailure {
  background-color: $color-error-main;
}
.alertWarning {
  background-color: #dc8e27;
}
.alertInformation {
  background-color: #005596;
}
