/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  padding: 25px;
  padding-top: 0;
  width: 100%;
  //background-color: #E6EAEA;
}

.loading-icon {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}
