/*Theme Green Styles*/
@import '~pjm-dls/dist/emerald/variables';
$app-primary-green: $color-primary !default;
$secondary-green: #ff6d00;

$sidebar-green: rgba($color-primary, 0.25) !default;
$sidebar-text-green: #868e96 !default;
$light-btn-link: #195141 !default;
$green-3: #012a2d;
$light-gray-01: #f9f9fc;
$color-light-green-bg: #ecf5f2;

.d-flex {
  display: flex !important;
}

//-Base-scss
.green {
  .MuiButton-root {
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 8px 16px !important;
    line-height: 1 !important;
    min-width: 100px !important;
  }
  .right-arrow {
    color: $color-primary;
    &:after {
      color: $color-primary;
    }
  }
  .text-warning {
    color: $color-warning-main !important;
  }
  .filter-dropdown-menu-body {
    font-size: $font-size-14 !important;
    padding-bottom: 1rem !important;
    .fdm-select-container {
      .MuiTypography-body1 {
        font-size: $font-size-14;
      }
    }
  }
  .MuiPopover-paper {
    .MuiTypography-body1 {
      font-size: $font-size-14;
    }
  }
  .sidebar-pagination {
    text-align: center;
    padding-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 2em;
      font-weight: 500;
      color: $color-primary;
      margin-right: 10px;
      margin-left: 10px;
      cursor: pointer;
    }
    i[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
    span {
      margin-left: 5px;
    }
    input {
      width: 35px;
      height: 25px;
      text-align: center;
    }
  }
  .MuiInputBase-input {
    font-size: $font-size-14;
  }
  .MuiMenu-paper {
    .MuiMenu-list {
      li.MuiListItem-root {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }
  .disabled-prop-async-auto {
    cursor: not-allowed;
    .MuiInputLabel-root,
    .MuiTypography-body1 {
      color: rgba($black, 26%) !important;
    }
    fieldset {
      border-color: rgba($black, 26%) !important;
    }
    & + .MuiFormHelperText-root {
      color: rgba($black, 26%) !important;
    }
  }
  .emerald-input.textbox .Mui-disabled {
    p {
      color: rgba($black, 26%);
    }
  }
}

//- Bootstrap file Style
.green {
  a,
  .card-link,
  .pjm-link,
  .pjm-link.text-primary {
    color: $color-primary;
    &:focus,
    &:hover {
      color: darken($color-primary, 10%);
    }
  }

  & .text-primary {
    color: $color-primary !important;
  }

  & .page-link {
    color: $color-primary;
    &:focus,
    &:hover {
      color: $color-primary;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $color-primary;
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $color-primary !important;
    color: $white !important;
  }

  & .btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-primary-light;
      border-color: $color-primary-light;
      color: $white !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #c8e6c9 !important;
    }

    &.lighten-3 {
      background-color: #a5d6a7 !important;
    }

    &.lighten-2 {
      background-color: #81c784 !important;
    }

    &.lighten-1 {
      background-color: #66bb6a !important;
    }

    &.darken-1 {
      background-color: #43a047 !important;
    }

    &.darken-2 {
      background-color: #388e3c !important;
    }

    &.darken-3 {
      background-color: #2e7d32 !important;
    }

    &.darken-4 {
      background-color: #1b5e20 !important;
    }

    &.accent-1 {
      background-color: #b9f6ca !important;
    }

    &.accent-2 {
      background-color: #69f0ae !important;
    }

    &.accent-3 {
      background-color: #00e676 !important;
    }

    &.accent-4 {
      background-color: #00c853 !important;
    }
  }
}

//Secondary
.green {
  .pjm-link.text-secondary {
    color: $secondary-green;
    &:focus,
    &:hover {
      color: darken($secondary-green, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-green !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-green !important;
    color: $white !important;
  }

  & .btn-secondary {
    background-color: $secondary-green;
    border-color: $secondary-green;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-green, 5%) !important;
      border-color: darken($secondary-green, 5%) !important;
      color: $white !important;
    }
  }
  .MuiButton-containedPrimary {
    background-color: $color-primary;
    border: 1px solid $color-primary;
    &:hover,
    &:focus,
    &:active {
      background-color: $color-primary-light;
      border: 1px solid $color-primary-light;
      color: $white !important;
    }
    &.Mui-disabled {
      background-color: rgba($color-text-dark, 12%);
      border-color: rgba($color-text-dark, 12%);
      color: rgba($color-text-dark, 46%);
    }
  }
  .MuiButton-outlinedPrimary {
    border: 1px solid $color-primary;
    color: $color-primary;
    &:hover,
    &:focus,
    &:active {
      background-color: rgba($color-primary, 8%) !important;
      border-color: $color-primary !important;
      color: $color-primary !important;
    }
    &.Mui-disabled {
      color: rgba($color-text-dark, 46%);
      border-color: rgba($color-text-dark, 46%);
      background-color: $white;
    }
  }
}

//_header.scss
.green .app-main-header {
  border-bottom: 1px solid #aaaaaa;
  & .app-toolbar-other {
    background: $color-primary !important;
    font-size: $font-size-16 !important;
    & .app-logo {
      color: $white !important;
      text-decoration: none;
    }
    & .border-thin {
      border-color: white;
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;

      & div {
        color: white;
      }
    }
    & .pjm-menu-icon .menu-icon {
      background-color: $white !important;
      color: $white;
    }
    .hamburger-menu-icon {
      color: $white;
    }
    & .pjm-menu-text {
      color: $white;
    }
    @media screen and (max-width: 767px) {
      & .header-language-select {
        display: none !important;
      }
    }
    .MuiInputBase-root {
      color: $color-text-dark;
    }
    & .header-language-select {
      & .MuiSelect-select.MuiSelect-select {
        color: white;
        width: 100px;
      }
      & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
      & .MuiInputBase-root {
        border-bottom: 1px solid $white;
      }
      & .MuiInput-underline:after {
        border-bottom: transparent;
      }
      & .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
      & .MuiInput-underline:before {
        border-bottom: none;
      }
      & .MuiOutlinedInput-input {
        padding: 9px 14px;
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: $color-surface-white;
      }
      & .MuiSelect-select {
        &:focus {
          background-color: transparent !important;
        }
        &:active {
          background-color: transparent !important;
        }
        &:visited {
          background-color: transparent !important;
        }
        &:hover {
          background-color: transparent !important;
        }
      }
      & .MuiInputBase-root {
        font-size: 0.85rem !important;
      }
      & .MuiSelect-select.MuiSelect-select option {
        color: $black;
      }
      & .MuiSelect-icon {
        color: $color-surface-white;
      }
    }
    & .action-button {
      color: white;
      padding: 7px 14px;
      font-size: 0.85rem !important;
      border: 1px solid $color-surface-white;
      border-radius: 4px;
      line-height: 18px;
      margin-right: 0 !important;
      position: relative;
      font-weight: 600;
      text-align: center;
      letter-spacing: 1px;
      span {
        position: absolute;
        background: red;
        border-radius: 50%;
        padding: 4px;
        right: -12px;
        top: -15px;
        font-size: 12px;
        min-width: 25px;
        min-height: 25px;
      }
    }
  }
  & .app-toolbar-dashboard {
    background: $white !important;
    font-size: $font-size-16 !important;
    & .app-logo {
      color: $color-primary;
      text-decoration: none;
    }
    & .border-thin {
      border-color: #555555;
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;

      & div {
        color: #555555;
      }
    }
    & .pjm-menu-icon .menu-icon {
      background-color: #555555 !important;
      color: $color-primary;
    }
    .hamburger-menu-icon {
      color: $color-primary;
    }
    & .pjm-menu-text {
      color: #555555;
    }
    & .header-language-select {
      & .MuiInputBase-root {
        border-bottom: 1px solid $white;
      }
      & .MuiInput-underline:after {
        border-bottom: transparent;
      }
      & .MuiSelect-select {
        color: #555555;
        width: 100px;
      }
      & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
      & .MuiInput-underline:before {
        border-bottom: none;
      }
      & .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
      & .MuiOutlinedInput-input {
        padding: 9px 14px;
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: #555555;
      }
      & .MuiSelect-select:focus {
        background-color: transparent;
      }
      & .MuiInputBase-root {
        font-size: 0.85rem !important;
      }
      & .MuiSelect-select.MuiSelect-select option {
        color: $black;
      }
      & .MuiSelect-icon {
        color: #555555;
      }
    }
    @media screen and (max-width: 767px) {
      & .header-language-select {
        display: none !important;
      }
    }
    & .user-notification {
      & .bg-primary {
        color: $color-primary !important;
        background-color: $color-surface-white !important;
      }
      & button:hover {
        background-color: transparent;
      }
    }
  }

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.green .color-theme-header {
  background-color: $color-primary;
}

//_sidebar.scss
.green .side-nav {
  background-color: $white !important;
  color: $sidebar-text-green !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));
  padding-top: 20px !important;

  & .user-profile {
    background-color: $color-primary;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: $pjm-border;
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-green, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $color-primary;
    }
  }
}

.green .nav-section {
  & .nav-header {
    color: $sidebar-text-green;
    border-color: $gray-400;
  }
}

.green .nav-collapse {
  & .nav-collapse-btn {
    color: $sidebar-text-green;

    &:focus,
    &:hover {
      background-color: $white;
      color: $color-primary;
    }
  }

  &.open {
    background-color: $white;

    & .nav-collapse-btn {
      background-color: $white;
      color: $color-primary;

      &:focus,
      &:hover {
        background-color: $white;
        color: $color-primary;
      }
    }
  }
}

.green .nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-green;

    &:focus,
    &:hover,
    &.active {
      background-color: $white;
      color: $color-primary;
    }

    &:after {
      background-color: $color-primary;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      background-color: $sidebar-green;
      color: $color-primary;
    }
  }
}

.green .nav-menu-item {
  & .nav-menu-link {
    &:focus,
    &:hover,
    &.active {
      background-color: $white;
      color: $color-primary;
    }
  }
}

/*Header top Navbar Styles*/
.green .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $color-primary;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $color-primary;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-green;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $color-primary;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $color-primary;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $color-primary;
    }
  }
}

.green .app-top-nav {
  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-green;
    }
  }
}

.green .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.green .header-notifications .app-notification {
  & .pjm-list-link {
    @include hover-focus-active {
      color: $color-primary;
      border-color: $color-primary;
    }
  }
}

//_app-module.scss
.green .module-nav {
  & li {
    & a.active {
      border-color: $color-primary;
    }

    & .pjm-link.active {
      color: $color-primary;
    }
  }
}

//_calendar.scss
.green .rbc-event {
  background-color: $color-primary;
}

.green .rbc-event.rbc-selected {
  background-color: darken($color-primary, 10%);
}

.green .rbc-slot-selection {
  background-color: rgba($color-primary, 0.7);
}

.green .rbc-toolbar button:active,
.green .rbc-toolbar button.rbc-active {
  background-color: rgba($color-primary, 0.9);
  border-color: $color-primary;
}

.green .rbc-toolbar button:active:hover,
.green .rbc-toolbar button.rbc-active:hover,
.green .rbc-toolbar button:active:focus,
.green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($color-primary, 0.9);
  border-color: $color-primary;
}

.green .rbc-toolbar button:focus {
  background-color: rgba($color-primary, 0.9);
  border-color: $color-primary;
}

.green .rbc-toolbar button:hover {
  background-color: rgba($color-primary, 0.9);
  border-color: $color-primary;
}

//_chat.scss
.green .chat-sidenav-title {
  color: $color-primary;
}

.green .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($color-primary, 45%);
  }
}

//_dashboard.scss
.green .contact-list {
  & i {
    color: $color-primary;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.green .Collapsible__trigger {
  background: $color-primary;
}

.green .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $color-primary;
  }
}

//_login.scss
.green .login-content .form-control {
  &:focus {
    border-color: $color-primary;
  }
}

//_portfolio.scss
.green .filter-with-bg-color ul li {
  .pjm-link {
    border-color: $color-primary;

    &:hover,
    &:focus,
    &.active {
      background-color: $color-primary;
    }
  }
}

//_card.scss
.green .profile-intro {
  & .icon {
    color: $color-primary;
  }
}

.green .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-green;
  }
}

//_tables.scss
.green .actions {
  color: $secondary-green;
}

.green .table-hover tbody tr:hover {
  background-color: rgba($color-primary, 0.075);
}

//Border Color

.green .border-primary {
  border-color: $color-primary !important;
}

// login page content

.green .app-logo-content {
  background-color: $color-primary;
}

.green .app-social-block {
  & .social-link button {
    border: solid 1px $color-primary;
    color: $color-primary;

    &:hover,
    &:focus {
      color: $white;
      background-color: $color-primary;
    }
  }
}

/*Button Group Styles*/
.green .btn-group,
.green .btn-group-vertical {
  > .pjm-btn {
    &.active {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  > .pjm-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $color-primary;
      &:hover,
      &:focus {
        background-color: rgba($color-primary, 0.12);
        color: $color-primary;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.green .pjm-fillchart-btn-close,
.green .pjm-onchart .pjm-badge-up,
.green .pjm-task-list-item:hover .pjm-text-hover {
  color: $color-primary;
}

.green .pjm-entry-title:before {
  background-color: $color-primary;
}

.green .pjm-card-ticketlist {
  & .pjm-task-list-item:hover .pjm-task-item-title,
  .pjm-link {
    color: $color-primary;
  }
}

.green .slick-dots li.slick-active button:before {
  border-color: $color-primary;
}

//Nav Styles
.green .nav-pills .nav-link.active,
.green .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $color-primary;
}

// Gradient Color Class
.green .bg-gradient-primary {
  @include gradient-directional($color-primary, lighten($color-primary, 16%), 0deg);
}

.green .bg-gradient-primary-x {
  @include gradient-x(darken($color-primary, 10%), lighten($color-primary, 16%), 70%, 100%);
}

//Profile style
.green .pjm-profile-banner {
  background-color: $color-background2;
  color: $white;
  & .pjm-link {
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-green;
    }
  }
}

.green .dropdown-item {
  &.active,
  &:active {
    background-color: $color-primary;
    color: $white;
  }

  &[class*='text-'] {
    &.active,
    &:active {
      background-color: transparent;
    }
  }
}
.green {
  .MuiDialog-paper {
    border-top-color: $color-primary;
  }
  .client {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $white;
    font-size: $font-size-16;
    font-weight: 500;
    &:hover {
      background-color: $color-primary-light;
      border-color: $color-primary-light;
      color: $white;
    }
  }
  .clientPlanTitle,
  .clientSeeMore {
    color: $app-primary;
  }
  .clientPlanTileAmount {
    color: $app-primary;
  }
  .clientPlanEdit {
    color: $light-btn-link;
  }
  .clientPlanProject {
    color: $green-3;
  }
  .clientSeeMore {
    text-decoration: underline;
    &:hover,
    &:active,
    &:visited {
      color: $color-primary-light;
      background-color: transparent;
    }
  }
  .clientSeeMoreLink {
    text-decoration: underline;
    color: $color-primary-light;
    &:hover,
    &:active,
    &:visited {
      color: $color-primary-light;
      background-color: transparent;
    }
  }
  .add-plan-dialog {
    .MuiDialog-paperScrollPaper {
      border-top-color: $app-primary;
      .add-plan-title {
        .MuiTypography-h6 {
          color: $color-text-dark;
          font-weight: 600;
          & + button {
            color: $color-primary;
          }
        }
      }
    }
    .MuiTypography-h6 {
      color: $color-text-dark;
    }
  }

  .yearly-budget-grid {
    // border:1px solid lighten($black, 12%);
    table {
      thead {
        background-color: $light-gray-01;
        th {
          color: $green-3;
        }
      }
    }
    .grand-yearly-total {
      h3 {
        span + span {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
  .card-title {
    color: $color-text-dark;
  }
  .capPlanTileTitle {
    color: $white;
  }
  .MuiInputLabel-outlined {
    span {
      color: $color-error-main;
    }
  }
  .MuiFormHelperText-root {
    font-size: $font-size-sm !important;
  }
  .MuiFormHelperText-root.Mui-error {
    margin-left: 14px;
  }
  .criteria-details-right-panel,
  .criteria-details {
    background-color: $color-light-green-bg;
  }
  .MuiSvgIcon-colorPrimary {
    color: $color-primary-light;
  }
  .emerald-input.textbox label {
    max-width: calc(100% - 24px) !important;
  }
  .filter-widget-dropdown-menu .filter-widget-dropdown-menu-header {
    color: $color-primary;
    font-weight: 600;
  }
  .search-filter-card .btn-def {
    height: auto;
  }
  .MuiButton-outlinedPrimary:not(:last-child),
  .MuiButton-containedPrimary:not(:last-child) {
    margin-right: 16px !important;
    margin-left: 0 !important;
  }
  .MuiButton-containedPrimary {
    &.personnel-btn {
      margin-right: 0 !important;
    }
  }
  .MuiDialogActions-spacing > :not(:first-child),
  .MuiButton-outlinedPrimary:not(:first-child),
  .MuiButton-containedPrimary:not(:first-child) {
    margin-left: 0;
  }
  .btn-group > .dropdown-toggle-split:not(:first-child) {
    margin-left: -16px !important;
    border: 0 !important;
    margin-right: 0 !important;
  }
  .text_heading_color {
    color: $color-text-dark !important;
    font-weight: 600 !important;
  }
  .MuiInputLabel-outlined.Mui-focused.MuiInputLabel-shrink[data-shrink='true'],
  .MuiInputLabel-shrink[data-shrink='true'] {
    background-color: $white !important;
    padding-right: 5px;
  }
}
