/*Breadcrumb Styles*/
@import '~pjm-dls/dist/styles/base/variables';
.breadcrumb {
  @include border-radius($border-radius-sm);
  color: $color-text-dark;
  background-color: $white;
  font-size: $font-size-14;
  padding: 0.25rem 0;
  a {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.breadcrumb-item + .breadcrumb-item {
  padding-right: 0;
  padding-left: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  color: #6c757d;
  content: '/';
  text-decoration: none !important;
}

.breadcrumb-item + .breadcrumb-item:hover {
  text-decoration: none;
  &::before {
    text-decoration: none;
  }
}

// .breadcrumb-item + .breadcrumb-item:hover::before {
//   text-decoration: none;
// }

.breadcrumb-item.active:hover {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #7f7f7f;
}

.breadcrumb-item.active::before {
  color: #7f7f7f;
  text-decoration: none;
}

// .breadcrumb-item + .breadcrumb-item::before {
//   //content: '\F2FB';
//   font: {
//     family: 'Futura Md BT';
//     size: 12px;
//   }
//   line-height: 1;
//   vertical-align: middle;
//   padding-left: 0;
// }
