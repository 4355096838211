/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $headings-font-weight;
  margin: 0 0 $headings-margin-bottom;
  color: $headings-color;
}

[class*='text-'] h1,
[class*='text-'] .h1 {
  color: inherit;
}

[class*='text-'] h2,
[class*='text-'] .h2 {
  color: inherit;
}

[class*='text-'] h3,
[class*='text-'] .h3 {
  color: inherit;
}

[class*='text-'] h4,
[class*='text-'] .h4 {
  color: inherit;
}

[class*='text-'] h5,
[class*='text-'] .h5 {
  color: inherit;
}

[class*='text-'] h6,
[class*='text-'] .h6 {
  color: inherit;
}

.text-strikethrough {
  text-decoration: line-through !important;
}

.desc-list {
  margin-bottom: 0;
  li {
    padding: $pjm-card-padding;
    border-bottom: 1px solid $gray-500;
    margin-left: -$pjm-card-margin;
    margin-right: -$pjm-card-margin;

    &:last-child {
      border-bottom: 0 none;
    }

    & .card-title {
      margin-bottom: 6px;
    }
  }

  &.even-odd {
    li {
      border-bottom: 0 none;

      &:nth-child(2n) {
        background-color: $gray-300;
      }
    }
  }
}

// Font Weight class
.pjm-font-weight-thin {
  font-weight: $font-weight-thin;
}

.pjm-font-weight-light {
  font-weight: $font-weight-light;
}

.pjm-font-weight-normal {
  font-weight: $font-weight-normal;
}

.pjm-font-weight-medium {
  font-weight: $font-weight-medium;
}

.pjm-font-weight-bold {
  font-weight: $font-weight-bold;
}

.pjm-font-weight-black {
  font-weight: $font-weight-black;
}

/*Font size Classes*/
/*8px*/
.pjm-fs-xxs {
  font-size: 0.5rem !important;
}

/*10px*/
.pjm-fs-xs {
  font-size: 0.625rem !important;
}

/*11px*/
.pjm-fs-11 {
  font-size: 0.6875rem !important;
}

/*12px*/
.pjm-fs-sm {
  font-size: $font-size-sm !important;
}

/*13px*/
.pjm-fs-13 {
  font-size: 0.8125rem !important;
}

/*14px*/
.pjm-fs-md {
  font-size: $font-size-base !important;
  line-height: 1;
}

/*15px*/
.pjm-fs-15 {
  font-size: 0.9375rem !important;
}

/*16px*/
.pjm-fs-16 {
  font-size: 1rem !important;
}

/*16px*/
.pjm-fs-lg {
  font-size: 1rem !important;
  line-height: 1;
}

/*20px*/
.pjm-fs-xl {
  font-size: 1.25rem !important;
  line-height: 1;
}

/*20px*/
.pjm-fs-20 {
  font-size: 1.25rem !important;
}

/*24px*/
.pjm-fs-xxl {
  font-size: 1.5rem !important;
  line-height: 1;

  @media screen and (max-width: 1199px) {
    font-size: 1.25rem !important; //20px
  }
}

/*28px*/
.pjm-fs-2xl {
  font-size: 1.75rem !important;
  line-height: 1;

  @media screen and (max-width: 1199px) {
    font-size: 1.25rem !important; //20px
  }
}

/*30px*/
.pjm-fs-xxxl {
  font-size: 24px !important;
  line-height: 1;

  @media screen and (max-width: 1199px) {
    font-size: 1.75rem !important; //28px
  }

  @media screen and (max-width: 991px) {
    font-size: 1.5rem !important; //24px
  }
}

/*40px*/
.pjm-fs-xlxl {
  font-size: 2.5rem !important;
  line-height: 1;

  @media screen and (max-width: 1199px) {
    font-size: 2rem !important; //32px
  }

  @media screen and (max-width: 991px) {
    font-size: 1.75rem !important; //28px
  }
}

.pjm-fs-cp {
  @media screen and (min-width: 1368px) {
    font-size: 1rem !important; //16px
    line-height: 1;
  }
}
