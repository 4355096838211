.capitalplandashboardbutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.capitalplandashboardbtncontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
